import '../styles/index.scss';
import 'bootstrap';
import './menu';

const currentUrl = window.location.href;
const urlObject = new URL(currentUrl);

if (currentUrl.includes('oferta')) {
  import('./accordion');
} else if (currentUrl.includes('realizacje')) {
  import('./gallery');
} else if (urlObject?.pathname === '/') {
  import('./swiper');
}
