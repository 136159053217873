const hamburgerElement = document.querySelector('.hamburger');
const navElement = document.querySelector('.nav');
const navLinks = document.querySelectorAll('.nav-list__link');

hamburgerElement.addEventListener('click', () => {
  hamburgerElement.classList.toggle('is-active');
  navElement.classList.toggle('is-active');
});

navLinks.forEach(link => {
  link.addEventListener('click', () => {
    hamburgerElement.classList.remove('is-active');
    navElement.classList.remove('is-active');
  });
});
